import queryString from 'query-string'
import { storeToRefs } from 'pinia'
import { useGameStore } from '~/store/game'
import { useLobbyGameApi } from '~/api/lobby-game'
import { useLoading } from '~/composables/useLoading'
import { TITLE_GAME, EMPTY_CONTENT, SORT } from '~/constants/lobby'
import { IItemCongGame, IProvider, ISortItem, IFiterGameCasinoItem } from '~/types/lobby.type'
import { RESPONSE_STATUS } from '~/config/constant'
import { PATH_GAME_URL } from '~/constants/path'
interface IQueryParam {
  path_alias: string
  limit: number
  page: number
  provider_code?: string
  sort_by?: string
}

export const useGame = () => {
  const { $device, $pinia, $axios } = useNuxtApp()
  const store = useGameStore($pinia)
  const route = useRoute()
  const { listFilterLobbyGames } = storeToRefs(store)
  const { LOBBY_CATEGORY, LIST_LOBBY_GAME } = useLobbyGameApi()
  const { loading, load, isLoading } = useLoading()
  const totalItemLobby = ref<number>(0)

  const allObject = ref<IProvider>({
    code: 'all',
    name: 'Tất cả',
    url: '',
    img: `${PATH_GAME_URL}icon-all-mb.webp`,
    display_name: 'Tất cả'
  })
  const listGame = ref<IItemCongGame[]>([])
  const listProvider = ref<IProvider[]>([])
  const currentSort = ref<ISortItem>(SORT[0])
  const totalItem = ref<number>(0)
  const totalItemPerPage = ref<number>(0)
  const limit = 30
  const page = ref<number>(1)
  const isShowSort = ref<boolean>(false)
  const placeholderCount = ref<number>(0)
  const currentProvider = ref<string>('')

  const isLoadMore = computed(() => {
    return totalItemPerPage.value < totalItem.value
  })

  const initData = async () => {
    page.value = 1
    const _query = { ...route.query }

    if (listFilterLobbyGames.value) {
      setListProvider(listFilterLobbyGames.value)
    }
    if (getItemBySort(_query.sort as string)) {
      currentSort.value = getItemBySort(_query.sort as string) ?? SORT[0]
      await fetchListGame(currentSort.value)
    } else {
      currentSort.value = SORT[0]
      await fetchListGame()
    }
  }

  function getItemBySort(value: string): ISortItem | undefined {
    return SORT.find((obj) => obj.typeUrl === value && obj.typeUrl !== '')
  }

  const handleFilterProvider = async (item: IProvider) => {
    page.value = 1
    const _query = { ...route.query }
    await navigateTo({
      query: { ..._query, provider: item?.code }
    })
    handleSort(SORT[0])
  }

  const handleSort = (item: ISortItem) => {
    page.value = 1
    currentSort.value = item
    const _query = { ...route.query }

    if (currentSort.value.type) {
      fetchListGame(item)
    } else {
      fetchListGame()
    }

    const updatedQuery = { ..._query }
    if (currentSort.value.typeUrl) {
      updatedQuery.sort = currentSort.value.typeUrl
    } else {
      delete updatedQuery.sort
    }

    navigateTo({
      query: updatedQuery
    })
  }

  const setListProvider = (data: IFiterGameCasinoItem[]) => {
    const type = route?.params?.type
    const filteredItem = data.find((item: IFiterGameCasinoItem) => item?.alias === type)
    listProvider.value = filteredItem?.providers || []
    const isAllExists = listProvider.value.some((item: IProvider) => item?.code === 'all')
    if (!isAllExists) {
      listProvider.value.unshift(allObject.value)
    }
  }

  const fetchGames = (queryUrl: string) => {
    return $axios.get(`${LIST_LOBBY_GAME}?${queryUrl}`)
  }

  // check on mobile, if it is not used then remove it
  const handleChangeCategoryMenu = (item: IItemCongGame) => {
    if (item?.alias || item?.link) {
      navigateTo(item?.alias ? item.alias : item.link)
    }
  }

  const fetchListGame = async (params: { type?: string } = {}, $state?: any) => {
    loading()
    const gameType = route.params.type as string
    const _query = { ...route.query }
    const partnerProvider = typeof _query?.provider === 'string' ? _query.provider.toLowerCase() : ''

    const sortItem = getItemBySort(_query.sort as string)
    const sort = params?.type || (sortItem ? sortItem.type : 'default')

    const queryParams: IQueryParam = {
      path_alias: gameType,
      limit,
      page: page.value
    }

    if (partnerProvider && partnerProvider !== 'all') {
      queryParams.provider_code = partnerProvider
      currentProvider.value = partnerProvider
    }

    if ((params.type || _query.sort) && sort !== 'default') {
      queryParams.sort_by = sort
    }

    const queryUrl = queryString.stringify(queryParams)

    try {
      const { data: response } = await fetchGames(queryUrl)

      if (response.status === RESPONSE_STATUS.STATUS_OK) {
        if (page.value > 1) {
          listGame.value = listGame.value.concat(response.data?.items)
        } else {
          listGame.value = response.data?.items
        }

        totalItem.value = response.data?.total || 0
        totalItemLobby.value = response.data?.total_game_lobby
        totalItemPerPage.value = limit * (page.value - 1) + response.data?.items.length
        store.setGameLists(listGame.value)

        if (totalItemLobby.value < 10) {
          isShowSort.value = false
        } else {
          isShowSort.value = true
        }

        if (response.data.items?.length < limit && $state) {
          $state?.complete()
        } else if ($state) {
          $state?.loaded()
        }
      }
    } catch (error: any) {
      console.log('error', error?.response?.data?.message)
    } finally {
      load()
    }
  }

  const changePagination = ($state?: any) => {
    page.value++
    if (route.query.sort) {
      fetchListGame({}, $state)
    } else {
      fetchListGame($state)
    }
  }

  const scollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  }

  const calculatePlaceholder = () => {
    const itemsCount = listGame.value.length
    let itemsPerRow
    if ($device.isDesktop) {
      itemsPerRow = 6
    } else {
      itemsPerRow = 2
    }

    const remainder = itemsCount % itemsPerRow
    placeholderCount.value = remainder === 0 ? 0 : itemsPerRow - remainder
    store.setPlaceholderCount(placeholderCount.value)
  }

  const fetchListFilterLobby = async () => {
    try {
      loading()
      const { data: response } = await $axios.get(`${LOBBY_CATEGORY}?alias=game`)
      if (response.status === RESPONSE_STATUS.STATUS_OK) {
        const listFilter = response?.data || []
        store.setListFilterLobbyGames(listFilter)
        setListProvider(listFilter)
      }
    } catch (error: any) {
      console.log('error', error?.response?.data?.message)
    } finally {
      load()
    }
  }

  const fetchLotteryGames = async () => {
    const queryUrl = queryString.stringify({
      path_alias: 'table-game',
      limit: 6,
      page: 1
    })
    loading()
    try {
      const { data: response } = await $axios.get(`${LIST_LOBBY_GAME}?${queryUrl}`)
      if (response.status === RESPONSE_STATUS.STATUS_OK) {
        listGame.value = response.data?.items
      }
    } catch (error: any) {
      console.log('error', error?.response?.data?.message)
    }
  }

  const fetchSlotsGames = async () => {
    const queryUrl = queryString.stringify({
      path_alias: 'no-hu',
      limit: 8,
      page: 1
    })
    loading()
    try {
      const { data: response } = await $axios.get(`${LIST_LOBBY_GAME}?${queryUrl}`)
      if (response.status === RESPONSE_STATUS.STATUS_OK) {
        listGame.value = response.data?.items
      }
    } catch (error: any) {
      console.log('error', error?.response?.data?.message)
    }
  }

  onMounted(() => {
    calculatePlaceholder()
  })

  watch(listGame, () => {
    calculatePlaceholder()
  })

  return {
    currentProvider,
    scollToTop,
    handleFilterProvider,
    initData,
    changePagination,
    listProvider,
    listGame,
    isLoading,
    totalItem,
    totalItemPerPage,
    EMPTY_CONTENT,
    SORT,
    isLoadMore,
    currentSort,
    handleSort,
    isShowSort,
    TITLE_GAME,
    calculatePlaceholder,
    placeholderCount,
    fetchListFilterLobby,
    fetchGames,
    handleChangeCategoryMenu,
    fetchLotteryGames,
    fetchSlotsGames
  }
}
